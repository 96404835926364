import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux';
import { remove, updateQuantity, add } from '../../redux/CartSlice';
import { FaTrash } from "react-icons/fa";
import image1 from '../jainnamkin/sample white.png'
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Cart = () => {
  const [responseId, setResponseId] = React.useState("");
  const [responseState, setResponseState] = React.useState([]);
  const [totalpay, settotalpay] = useState('50')
  const productitem = useSelector((state) => state.cart);
  console.log(productitem, 'showcart');

  const dispatch = useDispatch();

  const handleremove = (id) => {
    dispatch(remove(id));
    toast.success("Removed !", {
      position: "top-center"
    });
  }

  const handleUpdateQuantity = (id, quantity) => {
    const newQuantity = Math.max(1, parseInt(quantity, 10) || 1); // Ensure it's at least 1
    dispatch(updateQuantity({ id, quantity: newQuantity }));
  };
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");

      script.src = src;

      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }

      document.body.appendChild(script);
    })
  }

  const createRazorpayOrder = (amount) => {
    let data = JSON.stringify({
      amount: amount * 100,
      currency: "INR"
    })

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "http://localhost:5000/api/payment/orders",
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    }

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data))
        handleRazorpayScreen(response.data.amount)
      })
      .catch((error) => {
        console.log("error at", error)
      })
  }

  const handleRazorpayScreen = async (amount) => {
    const res = await loadScript("https:/checkout.razorpay.com/v1/checkout.js")

    if (!res) {
      alert("Some error at razorpay screen loading")
      return;
    }

    const options = {
      key: 'rzp_test_2QNAVoN2x0Npk8',
      amount: amount,
      currency: 'INR',
      name: "papaya coders",
      description: "payment to papaya coders",
      image: "https://papayacoders.com/demo.png",
      handler: function (response) {
        setResponseId(response.razorpay_payment_id)
      },
      prefill: {
        name: "papaya coders",
        email: "papayacoders@gmail.com"
      },
      theme: {
        color: "#F4C430"
      }
    }

    const paymentObject = new window.Razorpay(options)
    paymentObject.open()
  }

  const paymentFetch = (e) => {
    e.preventDefault();

    const paymentId = e.target.paymentId.value;

    axios.get(`http://localhost:5000/api/payment/payment/${paymentId}`)
      .then((response) => {
        console.log(response.data);
        setResponseState(response.data)
      })
      .catch((error) => {
        console.log("error occures", error)
      })
  }
  const navigate = useNavigate();
  const totalAmount = productitem.reduce((total, item) => total + (item.price * item.quantity), 0);
  // const handleContinueShopping = () => {
  //   navigate('/NamkinHome', { state: { section: 'NamkiProduct' } });
  //   setTimeout(() => {
  //     const targetElement = document.getElementById('booking-3');
  //     if (targetElement) {
  //       targetElement.scrollIntoView({ behavior: 'smooth' });
  //     }
  //   }, 500); // Delay to allow page navigation
  // };
  return (
    <>
      {/* Cart Title Section */}
      <ToastContainer />
      {/* Cart Product Wrapper */}
      <div className="cart_product_wrapper" style={{marginTop:'110px'}}>
        <div className="container">
          <div className="row">
            {/* Cart Products List */}
            <div className="col-lg-9 col-md-12 col-sm-12 col-12">
              <div className="btc_shop_single_prod_right_section">
                <h3>Your Cart Products ({productitem.length})</h3>
              </div>
              <div className="table-responsive cart-calculations">
                <table className="table">
                  <thead className="cart_table_heading">
                    <tr>
                      <th>item</th>
                      <th>product</th>
                      <th>&nbsp;</th>
                      <th>price</th>
                      <th>Quantity</th>
                      <th>Total price</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productitem.map((item, index) => (
                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td>
                          <div className="table_cart_img">
                            <figure>
                              <img
                                src={item.image}
                                alt={item.title}
                              />
                            </figure>
                          </div>
                          {/* <div className="table_cart_cntnt">
                            <h1>{item.title}</h1>
                          </div> */}
                        </td>
                        <td>   <p>{item.title}</p></td>
                        <td className="cart_page_price">{item.price}</td>
                        <td>
                        {/* set quinting selecting limite */}
                        {/* alo add plus minus in style  */}
                        <input
  type="number"
  value={item.quantity}
  min="1"
  onChange={(e) => handleUpdateQuantity(item.id, e.target.value)}
  
/>
                        </td>
                        <td className="cart_page_totl">{item.price * item.quantity}</td>
                        <td>
                          <button onClick={() => handleremove(item.id)}>
                            <FaTrash color='red' />
                          </button>
                        </td>
                      </tr>
                    ))}

                    {/* Continue Shopping and Total */}
                    <tr>
                      <td></td>
                      <td className="shop_btn_wrapper shop_car_btn_wrapper">
                    
                      </td>
                      <td></td>
               
                      <td className="cart_btn_cntnt">
                      <button
    // onClick={handleContinueShopping}
      className='continie-btn-shopp'
    >
   Continue Shopping
    </button>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* Coupon Code Section */}
              <div className="estimate_shiping_Wrapper_cntnt">
                <div className="btc_shop_single_prod_right_section">
                  <h1>Coupon Code Enter Here :</h1>
                  <div className="lr_nl_form_wrapper">
                    <input type="text" placeholder="Type Your Code" />
                    <button className='continie-btn-shopp' type="submit">Apply</button>
                  </div>
                </div>
              </div>
            </div>

            {/* Estimate Shipping & Checkout Section */}
            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <div className="shipping_Wrapper">
                <div className="btc_shop_single_prod_right_section">
                  <h1>Estimate Shipping :</h1>
                </div>
           

                {/* Shipping Cost Breakdown */}
                <div className="estimate_shiping_Wrapper_cntnt estimate_shiping_Wrapper_repsnse">
                  <table className="table">
                    <tbody>
                    <tr style={{borderBottomWidth:'1px'}}>
                        <th>Bill:</th>
                        <td>
                        <span>{productitem.reduce((total, item) => total + (item.price * item.quantity), 0)}</span>
                        </td>
                      </tr>
                      <tr style={{borderBottomWidth:'1px'}}>
                        <th>Discount:</th>
                        <td>
                          0
                        </td>
                      </tr>
                      
                      <tr style={{borderBottomWidth:'1px'}}>
                        <th className="cart_btn_cntnt">  Sub Total :</th>
                        <td className="cart_btn_cntnt">
                         <span>{productitem.reduce((total, item) => total + (item.price * item.quantity), 0)}</span>
                      </td>
                      
                      </tr>
                    </tbody>
                  </table>
                  <div className="shop_btn_wrapper shop_btn_wrapper_shipping mt-5">
                    <ul>
                      <li>
                     <Link to='/checkout'> <a href="#">Checkout</a></Link>  
                        {/* <button onClick={() => createRazorpayOrder(totalAmount)}>Payment of {totalAmount}.</button> */}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cart;
