import React from 'react';
import { FaMapMarkerAlt ,FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import ContactDetails from './ContactDetails';

const Contact = () => {
  return (
    <div className="contact-page">
      <div className="ck_title_main_wrapper float_left">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="ck_title_cont float_left">
                <p>Contact Us</p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="ck_title_cont float_left">
                <ul>
                  <li><a href="#">Home &nbsp; &gt;</a></li>
                  <li>Contact Us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      
 <ContactDetails/>
      <div className="x_contact_title_main_wrapper float_left padding_tb_100">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="ck_contact_inner_heading_wrapper float_left">
                <h4>get in touch</h4>
                <h3>Leave A Message</h3>
              </div>
            </div>
            
            <div className="col-lg-10 offset-lg-1 col-md-12 col-sm-12 col-12">
              <form>
                <div className="row">
                  <FormInput type="text" name="first_name" placeholder="First Name*" />
                  <FormInput type="text" name="last_name" placeholder="Last Name*" />
                  <FormInput type="email" name="email" placeholder="Email *" />
                  <FormInput type="text" name="subject" placeholder="Subject" />
                  <div className="col-md-12">
                    <div className="form-m">
                      <div className="form-group i-message contect_form1">
                        <textarea className="form-control require" name="message" required rows="5" placeholder="Message"></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="tb_es_btn_div">
                      <div className="response"></div>
                      <div className="tb_es_btn_wrapper conatct_btn2 cont_bnt">
                        <button type="submit" className="submitForm">send message !</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ContactInfo = ({ icon, title, content }) => (
  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 full_width">
    <div className="x_contact_title_icon_cont_main_box">
      <div className="x_contact_title_icon"><i className={icon}></i></div>
      <div className="x_contact_title_icon_cont">
        <h3><a href="#">{title}</a></h3>
        <p dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  </div>
);

const FormInput = ({ type, name, placeholder }) => (
  <div className="col-lg-6 col-md-6">
    <div className="form-pos contect_form1">
      <div className="form-group i-name">
        <input type={type} className="form-control require" name={name} required placeholder={placeholder} />
      </div>
    </div>
  </div>
);

export default Contact;
