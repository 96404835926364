import React from "react";

import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";

export const SideNavbarData = [
  {
    title: "Home",
    path: "/Namkeen",
    icon: <AiIcons.AiFillHome color="#ffffff" />,
    cName: "nav-text"
  },
  {
    title: "Namkeen",                                                                                               
    path: "/Products",
    icon: <FaIcons.FaCartPlus color="#ffffff"  />,
    cName: "nav-text"
  },
  {
    title: "Sweets",
    path: "/SweetsCategory",
    icon: <FaIcons.FaCartPlus color="#ffffff"/>,
    cName: "nav-text"
  },
  {
    title: " Contact Us",
    path: "/jainshreecontact",
    icon: <IoIcons.IoMdPeople color="#ffffff" />,
    cName: "nav-text"
  },

];
                                                                                                                                                                                              