import React from 'react'
import { useEffect } from 'react';
import TeamSlider from "./TeamSlider";
import JainNavbar from './JainNavbar'
import NamkiProduct from './NamkiProduct'
import TestimonialSlider from './TestimonialSlider';
import ContactDetails from './ContactDetails';
import Sweets from './Sweets';
import SideNavbar from './SideNavbar';

function Home() {

  return (
  <>
{/* <Router>
  <Routes>
    <Route path='/cart' Component={Cart} />
  </Routes>
</Router> */}

  <JainNavbar/>

  <TeamSlider/>
  <NamkiProduct  id="booking-3"/>
  <Sweets/>
  <TestimonialSlider/>
  <ContactDetails/>

  {/* footer save as hotel shree */}
  
  
  </>
  )
}

export default Home