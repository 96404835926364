import React from 'react';

import { FaMapMarkerAlt ,FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

const ContactDetails = () => {
  return (
    <div className="x_contact_title_main_wrapper float_left padding_tb_100">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="ck_contact_inner_heading_wrapper float_left">
              <h4>If Any Query</h4>
            

<h3>Contact Details</h3>
</div>
</div>

<div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 full_width">
<div className="x_contact_title_icon_cont_main_box">
  <div className="x_contact_title_icon">
  <FaMapMarkerAlt size={30}  />
  </div>
  <div className="x_contact_title_icon_cont">
    <h3><a href="#">Address</a></h3>
    <p>Mahakal chouraha, Madhya Pradesh, India 455001</p>
  </div>
</div>
</div>

<div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 full_width">
<div className="x_contact_title_icon_cont_main_box">
  <div className="x_contact_title_icon">
  <FaPhoneAlt  size={30}/>
  </div>
  <div className="x_contact_title_icon_cont">
    <h3><a href="#">Phone Number</a></h3>
    <p>+919009018195<br />+918989408339</p>
  </div>
</div>
</div>

<div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 full_width">
<div className="x_contact_title_icon_cont_main_box">
  <div className="x_contact_title_icon">
  <MdEmail size={30} />
  </div>
  <div className="x_contact_title_icon_cont">
    <h3><a href="#">Email Address</a></h3>
    <p>
      <a href="mailto:support@example.com">jainshreehotel@gmail.com</a><br />
      <a href="mailto:support@example.com">jainshreehotel@gmail.com</a>
    </p>
  </div>
</div>
</div>

</div>
</div>
</div>
);
};

export default ContactDetails;
