import React, { useState , useEffect, useRef } from 'react'


// ICONS
import * as FaIcons from "react-icons/fa"; //Now i get access to all the icons
import * as AiIcons from "react-icons/ai";

import { IconContext } from "react-icons";

// ROUTING



// DATA FILE
import { SideNavbarData } from "./SideNavbarData";

// STYLES
import "./SideNavbar.css";
import './css/animate.css'
import './css/bootstrap.min.css'
import './css/demo.css'

import './css/style.css'
import './css/style_VII.css'

import { useSelector } from "react-redux";
import logo from './jainnamkin/web logo jain shree.png'
import { Link } from "react-router-dom";
import { BsCart3 } from "react-icons/bs";

import "./Navbar.css";

import "./HamburgerMenu.css";

function Navbar() {
  const cart = useSelector((state) => state.cart);
 
  const [showMenu, setShowMenu] = useState(false); // Controls dropdown menu visibility
;
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);
 



  
  return (
    <>
     <IconContext.Provider value={{ color: "#FFF" }}>
      <div id="booking-5" className="ck_menu_inner float_left">
      
          <div className="ck_logo_wrapper ck_logo_wrapper_inner">
         
          <img  style={{height:'80px'}} src={logo} alt="" />
      
          </div>
          <div className="ck_navigation_right_wrapper">
           
      
            <div className="mainmenu d-xl-block d-lg-block d-md-block d-sm-none d-none">
              <ul className="main_nav_ul">
              <li className="has-mega gc_main_navigation">
                <Link  to='Namkeen'>    Home</Link>
               
               
                </li>
             
                <li className="has-mega gc_main_navigation">
                  <a href="#" className="gc_main_navigation">
                    Products
                  </a>
                  <ul>
                    <li className="parent">
                     <Link to='Products'>Namkeen</Link>
                    </li>
                    <li className="parent">
                    <Link to='SweetsCategory'>Sweets</Link>
                    </li>
                  </ul>
                </li>
           
           
  
                <li className="has-mega gc_main_navigation">
                <Link  to='jainshreecontact'>     Contact Us</Link>
               
               
                </li>
                <li>
                  <button onClick={showSidebar}>Bar</button>
                </li>
                {/* <Link to='/cart'>       <li className="has-mega gc_main_navigation  ">
                  <a href="#" className="gc_main_navigation">
                      <BsCart3 />
                  </a>
                        <p className='counterred' >{cart.length}</p> 
                  <ul className='buzz_cart_dropdowns' >
                  <li>
												<div class="cc_cart_wrapper1 menu-button float_left">
													<div class="cc_cart_img_wrapper">
														<img src="images/ft2.jpg" alt="cart_img"/>
													</div>
													<div class="cc_cart_cont_wrapper">
														<h4><a href="#">product name</a></h4>
														<p>Quantity : 2 × $45</p>
														<h5>$90</h5>
														<button type="button" class="close" data-dismiss="modal">×</button>
													</div>
												</div>
											</li>
                  </ul>
                </li> </Link> */}
              </ul>
            </div>
            <div className='cart-bar'>
            <div className='d-flex alie align-items-center' style={{gap:'25px', position:'relative'}}>
            <Link to='/cart'>       <li className="has-mega gc_main_navigation  ">
                  <a href="#" className="gc_main_navigation">
                      <BsCart3 color='ff9930'  size={20}/>
                  </a>
             
                        <p className='counterred' >{cart.length}</p> 
               
                </li> </Link>
                <div className="main main--demo-3">
      <div className="content content--demo-3">
        
        <div className="hamburger" onClick={showSidebar}>
        <div className="hamburger__line hamburger__line--01">
                    <div className="hamburger__line-in hamburger__line-in--01"></div>
                  </div>
                  <div className="hamburger__line hamburger__line--02">
                    <div className="hamburger__line-in hamburger__line-in--02"></div>
                  </div>
                  <div className="hamburger__line hamburger__line--03">
                    <div className="hamburger__line-in hamburger__line-in--03"></div>
                  </div>
        </div>
        </div>
    
    </div>
                </div>
          </div>
          </div>
        </div>
        <testing/>
        {showMenu && (
        <div className="dropdown-menu-animation">
          <ul>
            <li>
            <Link onClick={()=>showMenu(false)} to='Namkeen'>     Home</Link>
            </li>
            <li>
            <Link onClick={()=>showMenu(false)}  to='Products'>Namkeen</Link>
            </li>
            <li>
            <Link onClick={()=>showMenu(false)}  to='SweetsCategory'>Sweets</Link>
            </li>
            <li>
            <Link onClick={()=>showMenu(false)}  to='/jainshreecontact'>     Contact Us</Link>
            </li>
          </ul>
        </div>
      )}
        <Link to="#" className="menu-bars">
                  <FaIcons.FaBars onClick={showSidebar} />
                </Link>
                  <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
                          <ul className="nav-menu-items" onClick={showSidebar}>
                            <li className="navbar-toggle">
                              <Link to="#" className="menu-bars">
                                <AiIcons.AiOutlineClose color='#ffffff' />
                              </Link>
                            </li>
                
                            {SideNavbarData.map((item, index) => {
                              return (
                                <li  key={index} className={item.cName}>
                                  <Link to={item.path}>
                                    {item.icon}
                                    <span className='sidenavText'>{item.title}</span>
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        </nav>
        </IconContext.Provider>
    </>
  );
}

export default Navbar;
